html {
  height: 100%;
  margin: 0;
}
body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*  importing the fonts */
@font-face {
  font-family: Calibri;
  src: url('./assets/fonts/Calibri.ttf');
}  
@font-face {
  font-family: Tahoma;
  src: url('./assets/fonts/Tahoma.ttf');
}  
@font-face {
  font-family: Tahoma-regular;
  src: url('./assets/fonts/tahoma-regular.ttf');
}  
@font-face {
  font-family: opensans-semibold;
  src: url('./assets/fonts/OpenSans-Semibold.ttf');
}  
@font-face {
  font-family: opensans-regular;
  src: url('./assets/fonts/OpenSans-Regular.ttf');
}  
@font-face {
  font-family: opensans-bold;
  src: url('./assets/fonts/open-sans-bold.ttf');
}  
@font-face {
  font-family: opensans-italic;
  src: url('./assets/fonts/OpenSans-Italic.ttf');
}
@font-face {
  font-family: opensans-LightItalic;
  src: url('./assets/fonts/OpenSans-LightItalic.ttf');
}    
@font-face {
  font-family: opensans-italic-semibold;
  src: url('./assets/fonts/OpenSans-SemiboldItalic.ttf');
}  
